import { useData } from "@microsoft/teamsfx-react";
import { Tenant, TenantApi } from "../api/TenantApi";
import { useEffect } from "react";
import { useQuery } from "@tanstack/react-query";
import { useStoreContext } from "../context/StoreContext";
import { useTeamsFxContext } from "../context/TeamsFxContext";
import { QueryKey } from "../constants/api";

interface TenantHook {
  isLoading: boolean;
  isError: boolean;
  isSuccess: boolean;
  tenant: Tenant | null | undefined;
  refetchTenant: () => void;
}

export const useTenant = (): TenantHook => {
  // Get authenticated user data (from Teams)
  const { teamsUserCredential } = useTeamsFxContext();
  const {
    isTenantConfigured,
    setIsTenantConfigured,
    setIsApiConnectionAvailable,
  } = useStoreContext();
  const { data: teamsUser } = useData(async () => {
    if (teamsUserCredential) {
      return await teamsUserCredential.getUserInfo();
    }
  });
  // Note: I think it's not helping, if the user needs to accept the consent first
  // const isTokenAvailable = localStorage.getItem("authToken");

  // Get the Tenant from the API
  const {
    isLoading,
    isError,
    isSuccess,
    data: tenant,
    error,
    refetch: refetchTenant,
  } = useQuery({
    queryKey: [QueryKey.GetTenant, teamsUser?.tenantId],
    queryFn: () => TenantApi.getTenant(teamsUser?.tenantId),
    enabled:
      // Boolean(isTokenAvailable) &&
      !!teamsUser?.tenantId && isTenantConfigured === undefined,
    retry: false,
  });

  useEffect(() => {
    if (!!teamsUser?.tenantId && !isLoading) {
      setIsApiConnectionAvailable(true);

      if (error) {
        if (error?.message.indexOf("ERROR_TENANT_NOT_FOUND") > -1) {
          setIsTenantConfigured(!!tenant);
          return;
        }

        setIsApiConnectionAvailable(false);
        return;
      }

      if (tenant) {
        setIsTenantConfigured(true);
      }
    }
  }, [
    teamsUser,
    isLoading,
    tenant,
    error,
    setIsTenantConfigured,
    setIsApiConnectionAvailable,
  ]);

  return {
    isLoading,
    isError,
    isSuccess,
    tenant,
    refetchTenant,
  };
};
