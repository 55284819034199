"use client";

import { useEffect } from "react";
import posthog from "posthog-js";
import { PostHogProvider as PHProvider } from "posthog-js/react";

export function PostHogProvider({ children }: { children: React.ReactNode }) {
  useEffect(() => {
    const posthogHost = process.env.REACT_APP_POSTHOG_HOST;
    const posthogKey = process.env.REACT_APP_POSTHOG_KEY;

    if (posthogHost && posthogKey) {
      if (!posthog.__loaded) {
        console.log("Initializing ingredients");
        posthog.init(posthogKey, {
          api_host: posthogHost,
        });
      } else {
        console.log("Ingredients are already initialized");
      }
    } else {
      console.error("Ingredients are not configured");
    }
  }, []);

  return <PHProvider client={posthog}>{children}</PHProvider>;
}
