import { UserAchievement } from "./AchievementApi";
import { Api } from "./Api";
import { UserFeedback } from "./FeedbackApi";
import { Tenant } from "./TenantApi";

// Note: Sync with BE
export enum UserRole {
  SuperAdmin = "SuperAdmin",
  TenantAdmin = "TenantAdmin",
  User = "User",
}

export type User = {
  id: number;
  providerUserId: string;
  name: string;
  level: number;
  score: number;
  role: UserRole;
  dailyRecipe?: UserDailyRecipe;
  configurations?: UserConfiguration;
  feedback?: UserFeedback[];
  tenant?: Tenant;
  createdAt: Date | string;
  updatedAt: Date | string;
};

export type UserDailyRecipe = {
  id: number;
  quantity: number;
  recipe: Recipe;
  createdAt: Date | string;
  updatedAt: Date | string;
};

export type Recipe = {
  id: number;
  slug: string;
  name: string;
  type: string;
  ingredients: string[];
  level: number;
  likes: number;
  createdAt: Date | string;
  updatedAt: Date | string;
};

export type UserConfiguration = {
  id: number;
  userId: number; // TODO: Review, maybe not used?
  settings: UserSettings;
};

export type UserSettings = {
  dateFormat: string;
  timeFormat: string;
  notifications: {
    weeklyReminder: boolean;
    weeklySummary: boolean;
    monthlySummary: boolean;
    yearlySummary: boolean;
    achievements: boolean;
  };
};

interface UpdateUserRequest {
  id: number;
  providerUserId: string;
  name: string;
  role: UserRole;
}

// TODO: Add notification configurations as well
interface UpdateUserConfigurationRequest {
  providerUserId: string;
  dateFormat: string;
  timeFormat: string;
}

export type UserProfile = {
  id: string;
  name: string;
  score: number;
  level: number;
  achievements: UserAchievement[];
  badges: {
    gold: number;
    silver: number;
    bronze: number;
    other: number;
  };
};

export class UserApi {
  public static API_ENDPOINT = `users`;

  public static async getUser(
    providerUserId: string | undefined
  ): Promise<User | null> {
    if (!providerUserId) {
      return null;
    }

    const url = `${UserApi.API_ENDPOINT}/${providerUserId}`;
    const response = await Api.get(url);

    return response;
  }

  public static async getUserProfile(
    providerUserId: string | null | undefined
  ): Promise<UserProfile | null> {
    if (!providerUserId) {
      return null;
    }

    const url = `${UserApi.API_ENDPOINT}/${providerUserId}/profile`;
    const response = await Api.get(url);

    return response;
  }

  public static async updateUser(
    user: UpdateUserRequest
  ): Promise<User | null> {
    const url = `${UserApi.API_ENDPOINT}/${user.providerUserId}`;
    const response = await Api.patch(url, user);

    return response;
  }

  public static async updateUserConfiguration(
    configuration: UpdateUserConfigurationRequest
  ): Promise<User | null> {
    const { providerUserId, ...payload } = configuration;

    const url = `${UserApi.API_ENDPOINT}/${providerUserId}/configuration`;
    const response = await Api.patch(url, payload);

    return response;
  }
}
