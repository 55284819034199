import {
  Button,
  Caption1,
  Caption2,
  MessageBarActions,
  makeStyles,
  shorthands,
  tokens,
} from "@fluentui/react-components";
import { LineSkeleton } from "../../../../components/Skeletons/LineSkeleton";
import { useUserProfile } from "../../hooks/useUserProfile";
import { Alert } from "../../../../components/Alert";
import { ArrowCounterclockwise24Regular } from "@fluentui/react-icons";

interface DailyRecipeTipProps {}

// Note: Keep in sync with Slack
const TIP_MESSAGES: Record<number, string[]> = {
  0: [
    "Bravo! You've officially spread joy (and pizza) across the team. Your pizza prowess knows no bounds. What a legend! 🎉🍕 #PizzaMaestro #TeamJoy",
    "Well done! Your pizza generosity has left a trail of smiles across the team. Take a bow, Maestro! 🌟🍕 #TeamSpirit #SpreadJoy",
    "Kudos! Your pizza-sharing game is strong. Teammates everywhere are feeling the love. Keep up the great work! 🚀🍕 #PizzaChampion #SpreadTheLove",
    "Congratulations! Your pizza-sharing skills are unmatched. You've truly made a difference in boosting team morale. Let's keep the positivity rolling! 🎉🍕 #PizzaPro #TeamSpirit",
    "Way to go! You've emptied the pizza box and filled hearts with joy. Your dedication to spreading happiness is inspiring. Keep shining! ✨🍕 #JoyBringer #PizzaKing",
    "Impressive work! You've used up your pizza quota, but your impact on team camaraderie is everlasting. Cheers to your generosity! 🥳🍕 #PizzaMaven #SpreadHappiness",
  ],
  1: [
    "The pizza finale is near! With just one slice left, you're on the brink of completing today's pizza adventure. Who's in for a savory surprise? 🎉🍕 #FinalSlice #PizzaQuest",
    "Down to the wire! Only one slice remains in your daily pizza stash. Who will receive this last delectable token of appreciation? The anticipation is delicious! 🌟🍕 #LastSlice #SpreadTheJoy",
    "The home stretch! You're on your last slice of the day. Who will receive this final taste of pizza magic? It's time to make someone's day extra special! ✨🍕 #PizzaFinale #SpreadTheLove",
    "The countdown begins! With just one slice left, your pizza journey for the day is almost complete. Who will be the lucky recipient of your final slice of joy? 🚀🍕 #PizzaCountdown #SpreadHappiness",
    "The grand finale! You're down to your last slice of pizza for today. Who will receive this last slice of deliciousness and feel the warmth of your appreciation? 🎉🍕 #FinalSlice #TeamSpirit",
  ],
  2: [
    "Two more pizzas to go! You're on a roll. Keep the pizza love flowing – there's still room for more smiles. Who's next in line for your tasty virtual treat? 😋🍕",
    "Two slices left, and you're on fire! Keep the pizza party alive with your generous spirit. Who's next in line to receive a savory slice of appreciation? 🎉🍕 #PizzaFiesta #SpreadJoy",
    "Halfway there! With two slices remaining, you're spreading joy like a pizza maestro. Who will be the lucky recipient of your next delicious endorsement? 🌟🍕 #PizzaMagic #SpreadHappiness",
    "Two slices down, two to go! Your pizza generosity knows no bounds. Who's ready to join the celebration and share a virtual slice of happiness? 🚀🍕 #PizzaJoy #TeamSpirit",
    "You're on a roll with two slices left! Keep the pizza love flowing – there's plenty to go around. Who's craving a slice of virtual delight from the pizza champ? 😋🍕 #PizzaChampion #SpreadTheLove",
    "Two slices in the bank, and you're making waves! Keep the pizza spirit alive and share the joy with your teammates. Who's up next for a delicious virtual treat? 🎉🍕 #PizzaPalooza #SpreadTheSmiles",
  ],
  3: [
    "Three pizzas left, and the pizza party's just getting started! Keep the momentum going and spread the joy with your virtual slices. Who's next on your list of awesome colleagues to endorse? 🎉🍕 #PizzaParty #SpreadTheCheer",
    "You've got a trio of pizzas waiting to be shared! With three slices left, the possibilities are endless. Who will be lucky enough to receive your next virtual slice of appreciation? 🌟🍕 #PizzaMagic #SpreadTheLove",
    "Three slices to go! Keep the pizza love flowing and brighten someone's day with a savory virtual treat. Who's ready to join the fun and celebrate with a slice? 🚀🍕 #PizzaJoy #TeamSpirit",
    "Three delicious slices left in your pizza arsenal! Spread the happiness and send a virtual slice to a deserving colleague. Who's craving a taste of your awesome endorsement next? 😋🍕 #PizzaDelight #SpreadTheSmiles",
    "You're three slices away from a full pizza party! Keep sharing the love and boosting morale with your virtual pizzas. Who's up next for a slice of appreciation from the pizza pro? 🎶🍕 #PizzaFiesta #SpreadTheJoy",
  ],
  4: [
    "Fantastic four! Your pizza-sharing skills are top-notch. With four more slices, you've got the power to make four colleagues' day. Let the pizza party continue! 🍕💫",
    "Four slices left – you're on fire! Keep the pizza party going and spread the joy to your awesome colleagues. Who's next in line for a delicious virtual slice? 🔥🍕 #PizzaParty #SpreadTheLove",
    "You're rocking the pizza game with four slices to spare! Share the happiness and send a virtual slice to brighten someone's day. Who's ready for a taste of your awesome appreciation? 🌟🍕 #PizzaMagic #TeamSpirit",
    "Four delicious slices waiting to be shared! Keep the momentum going and boost morale with your virtual pizzas. Who will be lucky enough to receive your next slice of appreciation? 🚀🍕 #PizzaJoy #SpreadTheCheer",
    "You're just four slices away from making someone's day! Keep the positivity flowing and send a virtual slice to a deserving colleague. Who's craving a taste of your awesome endorsement next? 😋🍕 #PizzaDelight #SpreadTheSmiles",
    "Fantastic four – your pizza generosity knows no bounds! With four slices left, you have the power to spread joy to four lucky colleagues. Keep the pizza party alive and let the good times roll! 🎉🍕 #PizzaFiesta #SpreadTheJoy",
  ],
  5: [
    "High-five for pizza vibes! Five more chances to send out those delicious virtual slices. Who's ready for a high-five? 🖐️🍕",
    "High-five for pizza vibes! With five slices left, you've got plenty of opportunities to spread joy and appreciation to your awesome teammates. Who's ready for a virtual high-five? 🖐️🍕 #PizzaPower #SpreadTheLove",
    "Five slices left – you're on a roll! Keep the pizza party going strong and share the love with your fantastic colleagues. Who's next in line for a tasty virtual treat? 🎉🍕 #PizzaParty #SpreadJoy",
    "You've got five slices waiting to brighten someone's day! Keep the positivity flowing and send a virtual slice to a deserving teammate. Who's craving a taste of your awesome endorsement next? 😋🍕 #PizzaDelight #SpreadHappiness",
    "Five delicious slices up for grabs! Spread the joy and boost morale by sending out virtual pizzas to your fantastic teammates. Who's ready for a slice of appreciation? 🚀🍕 #PizzaMagic #SpreadTheCheer",
    "Five slices to share, five smiles to inspire! Keep the pizza love alive and make someone's day with a virtual slice of appreciation. Who's ready to join the pizza party? 🌟🍕 #PizzaJoy #SpreadSmiles",
  ],
  6: [
    "Half a dozen pizzas to share! You're the pizza maestro in the making. Keep the momentum going – six more opportunities to bring smiles to your team's faces! 🎉",
    "Half a dozen slices of pizza! The perfect amount for a tasty gathering. Get ready to share the joy of pizza with your friends!",
    "Six slices of pizza, six reasons to indulge! Whether you're sharing or savoring solo, enjoy every cheesy bite!",
    "With six slices of pizza, you're in for a treat! Share the love and spread the joy of pizza with those around you.",
    "Six slices of pizza? That's a whole lot of happiness in triangular form! Get ready to dig in and experience pizza bliss!",
    "A pizza with six slices? Sounds like the start of a delicious adventure! Gather your friends and get ready to enjoy the cheesy goodness!",
    "Six slices of pizza await! Whether you're hosting a party or having a cozy night in, get ready for a slice of happiness!",
  ],
};

const useStyles = makeStyles({
  cardDescription: {
    display: "flex",
    flexDirection: "column",
    ...shorthands.gap(tokens.spacingVerticalS),
  },
  cardDescriptionTip: {
    color: tokens.colorNeutralForeground3,
    fontStyle: "italic",
  },
});

export const DailyRecipeTip: React.FC<DailyRecipeTipProps> = () => {
  const styles = useStyles();

  const { isLoadingUser, isSuccessUser, isErrorUser, user, refetchUser } =
    useUserProfile();

  const getTipMessage = () => {
    const userRecipeQuantityRemaining = user?.dailyRecipe?.quantity ?? 0;
    const messages = TIP_MESSAGES[userRecipeQuantityRemaining];

    if (user?.dailyRecipe?.recipe?.slug === "pizza-moldy") {
      return 'Sometimes, life serves you a pizza you didn’t ask for. Enjoy this "special" slice of moldy goodness — it’s all part of the fun! Happy April Fool’s Day! 🎭';
    }

    // Pick a random message from the array
    return messages[Math.floor(Math.random() * messages.length)];
  };

  return (
    <div className={styles.cardDescription}>
      {isLoadingUser && (
        <>
          <LineSkeleton style={{ width: "100px" }} />
          <LineSkeleton style={{ width: "150px" }} />
          <LineSkeleton style={{ width: "200px" }} />
        </>
      )}

      {isSuccessUser && (
        <>
          <Caption1>{getTipMessage()}</Caption1>
          <Caption2 className={styles.cardDescriptionTip}>
            Tip: Endorse you teammates by mentioning the @PizzaTime bot and
            their @ColleagueName handle alongside with a 🍕 emoji.
          </Caption2>
        </>
      )}

      {isErrorUser && (
        <Alert
          intent="error"
          actions={
            <MessageBarActions
              containerAction={
                <Button
                  appearance="transparent"
                  icon={<ArrowCounterclockwise24Regular />}
                  onClick={() => refetchUser()}
                />
              }
            />
          }
        />
      )}
    </div>
  );
};
