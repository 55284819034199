import React, {
  PropsWithChildren,
  useCallback,
  useEffect,
  useState,
} from "react";
import { makeStyles, shorthands, tokens } from "@fluentui/react-components";
import { TeamsUserCredential } from "@microsoft/teamsfx";
import { useTeamsFxContext } from "../../context/TeamsFxContext";
import { containerBreakpoints } from "../../utils/breakpoints";

export interface LayoutProps extends PropsWithChildren<{}> {}

const useStyles = makeStyles({
  root: {
    display: "flex",
    flexDirection: "column",
    ...shorthands.padding(tokens.spacingVerticalL, tokens.spacingHorizontalXXL),

    ...containerBreakpoints.xs({
      ...shorthands.padding(tokens.spacingVerticalS, tokens.spacingHorizontalS),
    }),
  },
});

export const Layout: React.FC<LayoutProps> = ({ children }) => {
  const styles = useStyles();

  const { teamsUserCredential } = useTeamsFxContext();

  // Note: This is not the best place to store the token. but this component is a wrapper for the whole app.
  const syncAuthToken = useCallback(
    async (teamsUserCredential: TeamsUserCredential) => {
      const { ssoToken } = teamsUserCredential as any;

      if (ssoToken?.token) {
        localStorage.setItem("authToken", ssoToken.token);
        localStorage.setItem(
          "authTokenExpiration",
          ssoToken.expiresOnTimestamp
        );
      } else {
        // TODO: This is a temporary dirty fix for the issue that the token is not ready when the app is loaded.
        setTimeout(() => {
          syncAuthToken(teamsUserCredential);
        }, 300);
      }
    },
    []
  );

  useEffect(() => {
    if (teamsUserCredential) {
      syncAuthToken(teamsUserCredential);
    }
  }, [teamsUserCredential, syncAuthToken]);

  let refreshTokenTimeout: NodeJS.Timeout | string | number | undefined;
  useEffect(() => {
    refreshToken();

    return () => {
      clearTimeout(refreshTokenTimeout);
    };
  }, [teamsUserCredential]);

  const [isPageRefreshing, setIsPageRefreshing] = useState(false);

  const refreshPage = () => {
    if (isPageRefreshing) {
      // console.log("Page is already refreshing");
      return;
    }

    console.log("Your session has expired. Refreshing the page...");
    setIsPageRefreshing(true);
    window.location.reload();
  };

  const refreshToken = async () => {
    const cachedExpiration = localStorage.getItem("authTokenExpiration");

    if (!teamsUserCredential && cachedExpiration) {
      if (parseInt(cachedExpiration) < Date.now()) {
        // Clear the cached token, it's expired
        localStorage.removeItem("authToken");
        localStorage.removeItem("authTokenExpiration");

        // Refresh page to get a new token
        refreshPage();
        return;
      }
    }

    teamsUserCredential?.getToken("").then((token) => {
      if (token && token.expiresOnTimestamp) {
        // console.log("Token expires on: %s", new Date(token.expiresOnTimestamp));
        // console.log("Current time: %s", new Date());

        // Also, refresh the cached token
        if (
          !cachedExpiration ||
          parseInt(cachedExpiration) !== token.expiresOnTimestamp
        ) {
          // console.log("Syncing token in the cache");
          syncAuthToken(teamsUserCredential);
        }

        if (token.expiresOnTimestamp > Date.now()) {
          // console.log("Token is still valid");
        } else {
          // console.log("Token is expired");

          // Refresh page to get a new token
          refreshPage();
          return;
        }
      } else {
        // console.log("Token is not available");

        // Refresh page to get a new token
        refreshPage();
        return;
      }

      refreshTokenTimeout = setTimeout(() => {
        refreshToken();
      }, 60 * 1000); // Refresh token every minute
    });
  };

  // User has switched back to the tab
  const onFocus = () => {
    refreshToken();
  };

  const onVisibilityChange = () => {
    if (!document.hidden) {
      refreshToken();
    }
  };

  useEffect(() => {
    window.addEventListener("focus", onFocus);
    window.addEventListener("visibilitychange", onVisibilityChange);

    // Calls onFocus when the window first loads
    onFocus();

    // Specify how to clean up after this effect:
    return () => {
      window.removeEventListener("focus", onFocus);
      window.removeEventListener("visibilitychange", onVisibilityChange);
    };
  }, []); // eslint-disable-line

  return <div className={styles.root}>{children}</div>;
};
